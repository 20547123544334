<template>
  <div v-observe-visibility="{
    callback: visibilityChanged,
    intersection: {
      threshold: 0.1,
    },
  }" class="d-flex justify-content-center gap-5 flex-column display-screenshot align-items-center" :class="{show: isVisible}">
    <h1 class="title">
      <slot></slot>
    </h1>
    <div class="image-container container-fluid">
      <picture>
        <source :srcset="src[0]" type="image/webp">
        <source :srcset="src[1]" type="image/png"> 
        <img @load="imageLoaded" ref="imageSrc" class="rellax" data-rellax-percentage="0.5" :src="src[1]" alt="">
      </picture>
      <div class="description text-center" v-if="description">
        {{description}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: Array,
    description: String,
  },
  data() {
    return {
      isVisible: false
    }
  },
  mounted() {
    console.log("Mounted")
  },
  emits: ['loaded'],
  methods: {
    imageLoaded() {
      this.$emit('loaded')
    },
    visibilityChanged (isVisible) {
      this.isVisible = isVisible
      // console.log(entry)
    }
  }
}
</script>

<style lang="scss" scoped>

  .display-screenshot {

    overflow: hidden;
    
    opacity: 0;
    transform: translateX(-20%);
    transition: transform 0.5s cubic-bezier(.28,.17,.34,.98);

    &.show {
      opacity: 1;
      transform: translateX(0);

      h1 {
        z-index: 2;
        opacity: 1;
        transform: translateX(0);
      }

      .image-container {
        .description {
          opacity: 1;
        }
      }

    }

    h1 {
      opacity: 0;
      transform: translateY(50%);
      transition: transform 0.5s cubic-bezier(.28,.17,.34,.98) 0.25s;
    }

    .image-container {

      @include media-breakpoint-up(md) {
        max-height: 75%;
        max-width: 90%;
      }

      position: relative;

      .description {

        opacity: 0;
        transition: opacity 0.5s cubic-bezier(.28,.17,.34,.98) 0.35s;

        margin-top: 2em;

        font-size: 1.1em;
        padding: 0.8em;

        background: transparentize($background, 0.05);
        border: 1px solid $primary;

        // box-shadow: ;
        box-shadow: 10px 10px 38px -10px rgba(0,0,0,0.58);

        @include media-breakpoint-up(md) {
          position: absolute;
          bottom: 3em;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        object-fit: contain;
      }
    }
    
  }
</style>